import React from 'react';
import '../App.css'; // Este arquivo deve conter estilos CSS para garantir a responsividade

function App() {
  return (
    <div className="App">
      <img src={require('../8jq.gif')} alt="gif" className="gif-background" />
    </div>
  );
}

export default App;
